import React from "react"
import ReactDOM from "react-dom/client"
import "./i18n"
import * as serviceWorker from "./serviceWorker"

import Maintenance from "pages/Maintenance"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<Maintenance />)

serviceWorker.unregister()
