import React from "react"
import { Helmet } from "react-helmet"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"
const Gears = () => {
  return (
    <svg
      width={32}
      height={32}
      style={{ position: "absolute", bottom: 12, right: 12, zIndex: 100 }}
      viewBox="0 0 64 64"
      className="rotating"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{".st1{opacity:.2}.st2{fill:#231f20}"}</style>
      <g id="Layer_1">
        <circle
          cx={32}
          cy={32}
          r={32}
          style={{
            fill: "#76c2af",
          }}
        />
        <g className="st1">
          <path
            className="st2"
            d="M27.5 34c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5-4.5 2-4.5 4.5"
          />
        </g>
        <g className="st1">
          <path
            className="st2"
            d="M53.9 32.6c0-.5-.5-1.1-1-1.3l-4.1-1.5c-.5-.2-1.1-.8-1.3-1.3l-.7-1.6c-.2-.5-.2-1.3 0-1.8l1.9-3.9c.2-.5.1-1.2-.2-1.6l-2-2c-.4-.4-1.2-.5-1.6-.2L41 19.3c-.5.2-1.3.2-1.8 0l-1.6-.7c-.5-.2-1.1-.8-1.3-1.3l-1.5-4.1c-.2-.5-.8-1-1.3-1 0 0-.6-.1-1.4-.1s-1.4.1-1.4.1c-.5 0-1.1.5-1.3 1l-1.5 4.1c-.2.5-.8 1.1-1.3 1.3l-1.6.7c-.5.2-1.3.2-1.8 0l-3.9-1.9c-.5-.2-1.2-.1-1.6.2l-2 2c-.4.4-.5 1.2-.2 1.6l1.9 3.9c.2.5.2 1.3 0 1.8l-.7 1.6c-.2.5-.8 1.1-1.3 1.3l-4.1 1.5c-.5.2-1 .8-1 1.3 0 0-.1.6-.1 1.4s.1 1.4.1 1.4c0 .5.5 1.1 1 1.3l4.1 1.5c.5.2 1.1.8 1.3 1.3l.7 1.6c.2.5.2 1.3 0 1.8l-1.9 3.9c-.2.5-.1 1.2.2 1.6l2 2c.4.4 1.2.5 1.6.2l3.9-1.9c.5-.2 1.3-.2 1.8 0l1.6.7c.5.2 1.1.8 1.3 1.3l1.5 4.1c.2.5.8 1 1.3 1 0 0 .6.1 1.4.1s1.4-.1 1.4-.1c.5 0 1.1-.5 1.3-1l1.5-4.1c.2-.5.8-1.1 1.3-1.3l1.6-.7c.5-.2 1.3-.2 1.8 0l3.9 1.9c.5.2 1.2.1 1.6-.2l2-2c.4-.4.5-1.2.2-1.6l-1.9-3.9c-.2-.5-.2-1.3 0-1.8l.7-1.6c.2-.5.8-1.1 1.3-1.3l4.1-1.5c.5-.2 1-.8 1-1.3 0 0 .1-.6.1-1.4s-.1-1.4-.1-1.4M32 44c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10"
          />
        </g>
        <path
          d="M27.5 32c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5-4.5 2-4.5 4.5"
          style={{
            fill: "#4f5d73",
          }}
        />
        <path
          d="M53.9 30.6c0-.5-.5-1.1-1-1.3l-4.1-1.5c-.5-.2-1.1-.8-1.3-1.3l-.7-1.6c-.2-.5-.2-1.3 0-1.8l1.9-3.9c.2-.5.1-1.2-.2-1.6l-2-2c-.4-.4-1.2-.5-1.6-.2L41 17.3c-.5.2-1.3.2-1.8 0l-1.6-.7c-.5-.2-1.1-.8-1.3-1.3l-1.5-4.1c-.2-.5-.8-1-1.3-1 0 0-.6-.1-1.4-.1s-1.4.1-1.4.1c-.5 0-1.1.5-1.3 1l-1.5 4.1c-.2.5-.8 1.1-1.3 1.3l-1.6.7c-.5.2-1.3.2-1.8 0l-3.9-1.9c-.5-.2-1.2-.1-1.6.2l-2 2c-.4.4-.5 1.2-.2 1.6l1.9 3.9c.2.5.2 1.3 0 1.8l-.7 1.6c-.2.5-.8 1.1-1.3 1.3l-4.1 1.5c-.5.2-1 .8-1 1.3 0 0-.1.6-.1 1.4s.1 1.4.1 1.4c0 .5.5 1.1 1 1.3l4.1 1.5c.5.2 1.1.8 1.3 1.3l.7 1.6c.2.5.2 1.3 0 1.8l-1.9 3.9c-.2.5-.1 1.2.2 1.6l2 2c.4.4 1.2.5 1.6.2l3.9-1.9c.5-.2 1.3-.2 1.8 0l1.6.7c.5.2 1.1.8 1.3 1.3l1.5 4.1c.2.5.8 1 1.3 1 0 0 .6.1 1.4.1s1.4-.1 1.4-.1c.5 0 1.1-.5 1.3-1l1.5-4.1c.2-.5.8-1.1 1.3-1.3l1.6-.7c.5-.2 1.3-.2 1.8 0l3.9 1.9c.5.2 1.2.1 1.6-.2l2-2c.4-.4.5-1.2.2-1.6l-1.9-3.9c-.2-.5-.2-1.3 0-1.8l.7-1.6c.2-.5.8-1.1 1.3-1.3l4.1-1.5c.5-.2 1-.8 1-1.3 0 0 .1-.6.1-1.4s-.1-1.4-.1-1.4M32 42c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10"
          style={{
            fill: "#fff",
          }}
        />
      </g>
      <g id="Layer_2" />
    </svg>
  )
}

function Maintenance() {
  return (
    <React.Fragment>
      <Helmet>
        <title>We will be back soon | CarbonHub.ai</title>
      </Helmet>
      <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <div className="position-relative mb-3">
            <img src="/logo512.png" width={128} height={128} />
            <Gears />
          </div>
          <h1 className="fs-6 text-secondary">
            This page is currently under maintenance. We appreciate your
            patience and encourage you to check back soon.
          </h1>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Maintenance
